import { ethers } from "ethers";
import ethUtil from '@/utils/eth-utils'
const { utils } = ethers;
import { BigNumber } from "@ethersproject/bignumber";
const log = require('debug')('store:wallet');

import detectEthereumProvider from '@metamask/detect-provider';
import { erc20Artifact } from "@/contracts";
// let { erc20Artifact } = Contracts;
import ProjectConstants from '@/config/constants';

import {
    _findToken,
    _findTokenByAddress,
    _findKnownTokenByAddress,
    setAccounts,
    updateAccountBalance,
    resetWallet,
    switchChain,
    requestAccounts,
    addErc20Token,
    getContracts,
} from './wallet.func.js';

const ROPSTEN_ID = '0x3';
const BSC_ID = '0x38';
const BSC_TESTNET_ID = '0x61';
const HARDHAT_ID = '0x7a69';

let network = ProjectConstants.network;
let DAPP_CHAIN_ID = ProjectConstants.chainId;

const KEY_CONNECTED = 'connected';

const provider = window.ethereum;
let hasAccountRequest = false;    // true if connect wallet request 
let walletConnected = false;       // true, if user actually try to connected. false, if user HAS disconnected.

export const state = {
    ethereumDetected: false,
    isOperator: false,
    connected: false,
    address: '',
    chainId: 0,
    balance: '',
    tokens: [],         // { contract, address, symbol, name, decimals, totalSupply, balance }
}

export const getters = {
    getToken: (state) => (symbol) => {
        return _findToken(state, symbol);
    },

    getTokenBalance: (state) => (symbol) => {
        // log(`== wallet : getTokenBalance('${symbol}')`)
        let token = _findToken(state, symbol)
        if (token) {
            // log(`== wallet : getTokenBalance('${symbol}') , token found ${token.balance}`)
            return token.balance;
        }
        return '0'
    },

    hasOpRole: (state) => {
        return state.isOperator;
    }
}

export const mutations = {
    SET_DETECTED(state, value) {
        state.ethereumDetected = value;
    },
    SET_CONNECTED(state, value) {
        state.connected = value;
        if (value == false) state.address = '';
    },
    SET_ADDRESS(state, addr) {
        state.address = addr;
    },
    SET_BALANCE(state, balance) {
        state.balance = balance;
    },
    SET_CHAIN_ID(state, chainId) {
        state.chainId = chainId;
    },
    SET_ACCOUNT(state, {address, balance }) {
        state.address = address;
        state.balance = balance;

        walletConnected = true;
        localStorage.setItem(KEY_CONNECTED, '1');
    },
    RESET_WALLET(state) {
        state.connected = false
        state.address = ''
        state.balance = ''
        state.tokens = []
    
        walletConnected = false;
        localStorage.setItem(KEY_CONNECTED, '0');
    },
    ADD_TOKEN(state, token) {
        state.tokens.push(token);
    },
    UPDATE_TOKEN_BALANCE(state, { symbol, balance }) {
        let token = _findToken(state, symbol);
        if (token) {
            token.balance = balance;
        }
    },
    SET_OPERATOR(state, isOp) {
        state.isOperator = isOp;
    },
}

export const actions = {
    async init({ commit, state, dispatch }) {
        log('== wallet : init')

        const detectedProvider = await detectEthereumProvider();

        const anyProvider = new ethers.providers.Web3Provider(window.ethereum, "any");
        anyProvider.on("network", onNetworkChanged);

        if (detectedProvider) {
            // log('== wallet : Ethereum detected!')

            // check if wallet connected before.
            let hasConnected = localStorage.getItem(KEY_CONNECTED);
            if (hasConnected && hasConnected == '1') {
                walletConnected = true;
            }
            else {
                walletConnected = false;
            }

            commit('SET_DETECTED', true);

            detectedProvider.on("accountsChanged", onAccountChanged);
            detectedProvider.on("chainChanged", onChainChanged);
            detectedProvider.on("disconnect", onDisconnected);

            // log('== wallet init : waiting event, connection state = %s', detectedProvider.isConnected())
            if (detectedProvider.isConnected()) {
                onWalletAvailable();
            }

            detectedProvider.on("connect", onWalletAvailable);
        } else {
            // if the provider is not detected, detectEthereumProvider resolves to null
            // TODO: 
            // console.error('Please install MetaMask!')
        }

        async function onNetworkChanged(newNetwork, oldNetwork) {
            if (oldNetwork) {
                // window.location.reload();
            }
        }

        async function onWalletAvailable(connectionInfo) {
            log('== ethereum : connect')

            if (provider.isConnected()) {
                try {
                    let chainId = await provider.request({
                        method: 'eth_chainId'
                    })

                    onChainChanged(chainId);
                }
                catch (e) {
                    log('onWalletAvailable: %O', e)
                }
            }
        }

        async function onChainChanged(chainId) {
            log('== ethereum : chainChanged')

            setChain(commit, chainId);
        }

        async function onAccountChanged(accounts) {
            log('== ethereum : accountChanged')

            setAccounts(commit, accounts);
        }

        function onDisconnected(error) {
            log('== ethereum : disconnect : %O', error)

            resetWallet(commit)
        }
    },
    setConnected({ commit }, { isConnected, addr }) {
        // log("Wallet setConnected : %s , address = %s", isConnected, addr)

        if (isConnected) {
            commit('SET_CONNECTED', true)
            if (addr) {
                commit('SET_ADDRESS', addr);
                
            }
        }
        else {
            commit('SET_CONNECTED', false);
            commit('SET_ADDRESS', '');
        }
    },
    async connect({ commit, state }) {
        log('Connect wallet called : chainId = %s', state.chainId);

        walletConnected = true;
        localStorage.setItem(KEY_CONNECTED, '1');
        hasAccountRequest = true

        if (state.chainId == DAPP_CHAIN_ID) {
            setChain(commit, state.chainId);
            return;
        }

        log('--- calling switch chain !!')

        // change chain first
        // after chain changed, request wallet again
        await switchChain(state, DAPP_CHAIN_ID)
    },
    async disconnect({ commit }) {

        log('--- action disconnect');

        walletConnected = false;
        localStorage.setItem(KEY_CONNECTED, '0');
        resetWallet(commit);
    },
    async updateBalance({ commit, state }) {
        updateAccountBalance(state, commit);
    },
    async updateTokenBalance({commit}) {
        try {
            if (state.address > '') {
                for (let i=0; i<state.tokens.length; i++) {
                    let nBalance = await state.tokens[i].contract.balanceOf(state.address);
                    let balance = utils.formatUnits(nBalance, state.tokens[i].decimals);
                    commit('UPDATE_TOKEN_BALANCE', { symbol: state.tokens[i].symbol, balance });
    
                    // log(`== wallet : token[${state.tokens[i].symbol}] balance = %s`, state.tokens[i].balance)
                }
            }
        }
        catch(e) {
            log('updateTokenBalance : %O', e);
        }
    },
    async addToken({ commit, state }, address) {
        await addErc20Token({ commit, state }, address);
    },

    setOperator({ commit }, isOp) {
        commit('SET_OPERATOR', isOp);
    }
}

async function setChain(commit, chainId) {
    // log("== wallet: setChainId: %s", chainId);

    // state.chainId = chainId;
    commit('SET_CHAIN_ID', chainId);

    // user disconnected or initial state
    if (walletConnected == false) {
        log('============ skip setChain : walletConnected = %s', walletConnected);
        if (! hasAccountRequest) return;
    }

    log('--- Chain Changed : Chain Equals = %s, Has Request = %s', chainId == DAPP_CHAIN_ID, hasAccountRequest);
    let needRequest = hasAccountRequest;
    hasAccountRequest = false

    // check
    if (chainId == DAPP_CHAIN_ID) {
        try {
            let accounts = await provider.request({ method: "eth_accounts" });

            // already connected and selected
            setAccounts(commit, accounts);

            if (accounts.length == 0 && needRequest) await requestAccounts()
        }
        catch (e) {
            log('chainChanged-error : %O', e)
        }
    }
    else {
        resetWallet(commit);
    }
}
