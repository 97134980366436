


import ProjectConstants from '@/config/constants';

let network = ProjectConstants.network;
let contractAddress = require(`@/contracts/${network}/contract-address.json`);

import erc20Artifact from "@/contracts/ERC20.json";
import poctArtifact from "@/contracts/POCT.json";
import crowdsaleArtifact from "@/contracts/POCTCrowdSale.json";
import farmArtifact from "@/contracts/POCTFarm.json";
import publicSaleArtifact from "@/contracts/POCTPublicSale.json";

if (ProjectConstants.network == 'bsc-mainnet') {
    contractAddress['USDT'] = '0x55d398326f99059ff775485246999027b3197955';
    contractAddress['BTCB'] = '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c';
}

export {
    contractAddress,
    
    // Artifacts
    erc20Artifact,
    poctArtifact,           // special ERC20 for test purpose
    crowdsaleArtifact,
    publicSaleArtifact,
    farmArtifact,           // Mining Farm & Reward Farm
}