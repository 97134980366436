import { ethers } from "ethers";
import ethUtil from '@/utils/eth-utils'
const { utils } = ethers;
import { BigNumber } from "@ethersproject/bignumber";
const log = require('debug')('store:wallet.function');

import { contractAddress, erc20Artifact, farmArtifact } from "@/contracts";
import ProjectConstants from '@/config/constants';
let { contractNames } = ProjectConstants;

const DAPP_CHAIN_ID = ProjectConstants.chainId;
const provider = window.ethereum;

const _knownTokens = [
  {
    contract: null,             // to be filled
    address: '0x55d398326f99059ff775485246999027b3197955',
    symbol: 'USDT',
    name: 'BSC-USD',
    decimals: 18,
    totalSupply: BigNumber.from(4_000_000_000),     // NOT important
    balance: ''
  },
  {
    contract: null,             // to be filled
    address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    symbol: 'BTCB',
    name: 'BTCB Token',
    decimals: 18,
    totalSupply: BigNumber.from(21_000_000),     // NOT important
    balance: ''
  }
];

export function _findToken(state, symbol) {
  for (let i = 0; i < state.tokens.length; i++) {
    if (state.tokens[i].symbol == symbol) {
      return state.tokens[i];
    }
  }
  return null;
}

export function _findTokenByAddress(state, addr) {
  for (let i = 0; i < state.tokens.length; i++) {
    if (state.tokens[i].address == addr) {
      return state.tokens[i];
    }
  }
  return null;
}

export function _findKnownTokenByAddress(state, addr) {
  for (let i = 0; i < _knownTokens.length; i++) {
    if (_knownTokens[i].address == addr) {
      return _knownTokens[i];
    }
  }
  return null;
}


export async function setAccounts(commit, accounts) {
  let walletProvider = null;
  // log("== wallet : setAccounts() : %O", accounts);

  if (null == walletProvider) {
    // log('== wallet : setAccount()')
    walletProvider = new ethers.providers.Web3Provider(window.ethereum);
  }

  if (accounts.length > 0) {
    log('accounts : %O', accounts);

    try {
      let address = accounts[0];
      let gwei = await walletProvider.getBalance(address);

      commit('SET_ACCOUNT', {
        address,
        // signer: walletProvider.getSigner(),
        balance: utils.formatUnits(gwei, "ether"),
        ready: true
      });
    }
    catch (e) {
      log('setAccounts : %O', e);
    }
  } else {
    resetWallet(commit);
  }
}

export async function updateAccountBalance(state, commit) {
  let walletProvider = null;
  // log("--- updateAccount :");

  if (null == walletProvider) {
    log('== wallet : updateAccountBalance(), new eth provider ')
    walletProvider = new ethers.providers.Web3Provider(window.ethereum);
  }

  if (state.address > '') {
    try {
      let address = state.address;
      let gwei = await walletProvider.getBalance(address);
      commit('SET_BALANCE', utils.formatUnits(gwei, "ether"));
    }
    catch (e) {
      log('updateAccountBalance : %O', e);
    }
  }
}

export function resetWallet(commit) {
  log('resetWallet()');
  commit('RESET_WALLET');
}

export async function switchChain(state, chainId) {
  if (!provider) {
    window.alert("Install wallet (Metamask) first");
    return false;
  }

  if (state.chainId != chainId) {
    log("--- switching to chain : %s,  old chain = %s ", chainId, state.chainId);

    try {
      let res = await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
      // log('Chain req response : %O', res)
      return true;

    } catch (switchError) {
      if (switchError.code === 4902) {
        // EIP-3085
        if (chainId == '0x38') ethUtil.addBSC();
        else if (chainId == '0x61') ethUtil.addBSCTestnet();
        else ethUtil.addChain(chainId, ProjectConstants.networkDisplay,
          ProjectConstants.coinName,
          [ProjectConstants.rpcUrl],
          [ProjectConstants.explorerUrl], []);

      } else {
        if (switchError.code === 4001) {
          log('User rejected switch chain to : %s', chainId)
        }
        else {
          log("Ethereum change network error : %O", switchError);
        }
      }
      return false;
    }
  }
  else {
    log("--- switchChain(chainId) : chainId =  %s", chainId);
    return true
  }
}

export async function requestAccounts() {
  try {
    let accounts = await provider.request({
      method: "eth_requestAccounts",
    });

    log('===== requestAccounts() .. %O', accounts)

    // accountChanged(accounts)
  } catch (e) {
    if (e.code === 4001) {
      log('User rejected to connect wallet')
    }
    else {
      log('requestAccounts : %O', e);
    }
  }
}

export async function addErc20Token({ commit, state }, address) {
  if (state.chainId != DAPP_CHAIN_ID) {
    // log('addToken() : chainId(%s) not supported', state.chainId);
    return;
  }

  if (_findTokenByAddress(state, address)) {
    // log('addToken() : Address already exists');
    return;
  }

  try {
    let walletProvider = new ethers.providers.Web3Provider(window.ethereum);
    if (state.chainId != ProjectConstants.chainId) {
      walletProvider = new ethers.providers.JsonRpcProvider(ProjectConstants.rpcUrl)
    }

    let erc20 = new ethers.Contract(address, erc20Artifact.abi, walletProvider);

    // log('addToken() : ERC20 -- token : %O', erc20)
    let decimals = 18;
    let balance = '';

    if (state.address > '') {
      decimals = await erc20.decimals();
      let nBalance = await erc20.balanceOf(state.address);
      balance = utils.formatUnits(nBalance, decimals);
    }

    let token = _findKnownTokenByAddress(state, address);
    if (token) {
      token.contract = erc20;
      token.balance = balance;

      // log('Add Known Token %s', token.symbol);
    }
    else {
      token = {
        contract: erc20,
        address,
        decimals,
        symbol: await erc20.symbol(),
        name: await erc20.name(),
        totalSupply: await erc20.totalSupply(),
        balance
      }
    }

    commit('ADD_TOKEN', token);
    // log('addToken: %s\n%s', token.symbol, token.name);
  }
  catch (e) {
    log('addToken: %O', e);
  }
}

export function getContracts() {
  let walletProvider = null;

  if (['ethereum', 'ropsten', 'kovan', 'rinkeby', 'goerli'].indexOf(ProjectConstants.network) >= 0) {
    walletProvider = new ethers.providers.Web3Provider(window.ethereum, ProjectConstants.network);
  }

  if (walletProvider == null) {
    walletProvider = new ethers.providers.JsonRpcProvider(ProjectConstants.rpcUrl)
  }

  let { contractNames } = ProjectConstants;

  let stakeToken = new ethers.Contract(
    contractAddress[contractNames.stakeToken],
    erc20Artifact.abi,
    walletProvider
  );
  let rewardToken = new ethers.Contract(
    contractAddress[contractNames.rewardToken],
    erc20Artifact.abi,
    walletProvider
  );
  let miningFarm = new ethers.Contract(
    contractAddress[contractNames.miningFarm],
    farmArtifact.abi,
    walletProvider
  );

  return {
    walletProvider,
    stakeToken,
    rewardToken,
    miningFarm,
  };
}
