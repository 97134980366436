import { ethers } from "ethers";
import detectEthereumProvider from '@metamask/detect-provider';
import erc20Artifact from "@/contracts/ERC20.json";
import Big from 'big.js'

// Reference :
// Create your first Ethereum dAPP with Web3 and Vue.JS (Part 1)
// https://itnext.io/create-your-first-ethereum-dapp-with-web3-and-vue-js-c7221af1ed82
//
//
// Ethers.js 와 web3.js 비교
// https://blog.infura.io/ethereum-javascript-libraries-web3-js-vs-ethers-js-part-ii/#section-8-ethers-working-code
//
const NETWORKS = {
    '0x1': 'Main Net',
    '0x2': 'Deprecated Morden test network',
    '0x3': 'Ropsten test network',
    '0x4': 'Rinkeby test network',
    '0x5': 'Goerli Test Network',
    '0x2a': 'Kovan test network',   // 42
    '0x38': 'BSC Mainnet',        // 56, Binance Smart Chain Testnet 
    '0x61': 'BSC Testnet',        // 97, Binance Smart Chain Testnet 
    '0x539': 'Ganache Blockchain',   // 1337, Ganache standalone node 
    '0x7a69': 'Hardhat node'     // 31337, Hardhat network
}

async function detect() {
    const provider = await detectEthereumProvider()

    if (provider) {
        console.log('Ethereum successfully detected!')

        // setDefaultHandler()
        // From now on, this should always be true:
        // provider === window.ethereum

        // Access the decentralized web!
        // startDApp()

        // Legacy providers may only have ethereum.sendAsync
        // const chainId = await provider.request({
        //     method: 'eth_chainId'
        // })

        return provider
    } else {

        // if the provider is not detected, detectEthereumProvider resolves to null
        console.error('Please install MetaMask!')
        return null
    }
}

/**
 * Can request RPC over ethereum:
 * - actual connection to account needs 'eth_requestAccounts'
 * 
 * @returns true if ethereum can do RPC
 */
function isAvailable() {
    if (typeof window.ethereum !== 'undefined') {
        console.log('MetaMask is installed!');
        let ethereum = window.ethereum

        console.log('Ethereum is connected : ', ethereum.isConnected())
        return ethereum.isConnected()
    }
    return false
}

/*
* Setup default ethereum wallet event handler
*/
function setDefaultHandler() {
    let ethereum = window.ethereum

    console.log('Setting default handler')

    if (!ethereum) {
        console.log('No ethereum detected')
        return
    }

    ethereum.on('connect', (connectInfo) => {
        console.log('...connect: Connection info :', connectInfo)
    });
    ethereum.on('accountsChanged', (accounts) => {
        console.log('...accountsChanged:', accounts)
        // Handle the new accounts, or lack thereof.
        // "accounts" will always be an array, but it can be empty.
    });
    ethereum.on('disconnect', (error) => {
        console.log('...disconnect:', error)
    });
    ethereum.on('chainChanged', (chainId) => {
        console.log('...chainChanged:', chainId)
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
    });
    ethereum.on('message', (message) => {
        console.log('...message:', message)

    });
}

/*
* 1. Check for injected web3 (mist/metamask)
* 2. If metamask/mist create a new web3 instance and pass on result
* 3. Get networkId - Now we can check the user is connected to the right network to use our dApp
* 4. Get user account from metamask
* 5. Get user balance
*/
async function connectWallet() {
    // NEW : metamask 
    if (typeof window.ethereum !== 'undefined') {
        let ethereum = window.ethereum

        if (ethereum.isConnected()) {
            console.log("Wallet is Ready", ethereum)

            try {
                // Access the user's accounts (per EIP-1102)
                let accChanged = await ethereum.request({ method: 'eth_requestAccounts' })
                console.log('Account changed by call : ', accChanged)
                let address = accChanged.length > 0 ? accChanged[0] : ''

                // A Web3Provider wraps a standard Web3 provider, which is
                // what Metamask injects as window.ethereum into each page
                // provider

                // The Metamask plugin also allows signing transactions to
                // send ether and pay to change state within the blockchain.
                // For this, you need the account signer...
                let provider = new ethers.providers.Web3Provider(window.ethereum)

                console.log('---- Signer : ', provider.getSigner())
                return {
                    provider,
                    address,
                    signer: provider.getSigner(),
                }
            }
            catch (e) {
                if (e && e.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    // If this happens, the user rejected the connection request.
                    console.log('Please connect to MetaMask.');
                }
                else {
                    console.error(e)
                }
            }
        }
        else {
            console.log('--- Metamask : Not yet initialized')
        }
    }

    return {
        provider: null,
        signer: null
    }
}

async function getChainId() {
    const provider = await detectEthereumProvider()

    if (provider) {
        const chainId = await provider.request({
            method: 'eth_chainId'
        })

        return chainId
    }

    return null
}

// chainId : '0x1' // for mainnet
async function switchChain(chainId) {
    if (!window.ethereum) {
        console.error('Ethereum provider not found !')
        return null
    }

    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId }],
        });
    } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
            // TODO: addChain()
        }
        else {
            console.log('Ethereum change network error', switchError)
        }
        return null
    }
}

// EIP-747 : Add custom token
async function requestWatchAsset(address, symbol, decimals, image) {
    if (!window.ethereum) {
        console.error('Ethereum provider not found !')
        return false;
    }

    try {
        return await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',  // Initially only supports ERC20, but eventually more!
                options: {
                    address,
                    symbol,
                    decimals,
                    image // A string url of the token logo
                  },
            },
        });
    } catch (err) {
        // This error code indicates that the token has not been added to MetaMask.
        if (err.code === 4902) {
            // TODO: 
            console.log('')
        }
        else {
            console.log('Ethereum add token error', err)
        }
    }
    return false;
}


// EIP-747 : Add custom token
async function addToken(address, image = '') {
    if (!window.ethereum) {
        console.error('Ethereum provider not found !')
        return false;
    }

    try {
        let walletProvider = new ethers.providers.Web3Provider(
            window.ethereum
        );

        let erc20Contract = new ethers.Contract(
            address,
            erc20Artifact.abi,
            walletProvider
        );

        let symbol = await erc20Contract.symbol();
        let decimals = await erc20Contract.decimals();
        return await requestWatchAsset(address, symbol, decimals, image);
    } catch (err) {
        console.log('addToken : %O', err);
    }
    return false
}

async function addChain(chainId, chainName, currency, rpcUrls, blockExplorerUrls = [], iconUrls = []) {
    //  EIP-3085
    // 
    // AddEthereumChainParameter
    let currencyName = 'Ethereum'
    if (currency == 'BNB') {
        currencyName = 'Binance Coin'
    }

    if (!window.ethereum) {
        console.error('Ethereum provider not found !')
        return null
    }

    let chainParams = {
        chainId: chainId,   // A 0x-prefixed hexadecimal string
        chainName: chainName,
        nativeCurrency: {
            name: currencyName,
            symbol: currency, // 2-6 characters long
            decimals: 18,
        },
        rpcUrls: rpcUrls,
        blockExplorerUrls: blockExplorerUrls,   // optional
        iconUrls: iconUrls      // Currently ignored.
    }

    try {
        let nullOk = await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [chainParams]
        })
        if (nullOk == null) return true
    }
    catch (e) {
        console.log(e)
        return false
    }
}

async function addBSC() {
    return addChain("0x38", "Binance Smart Chain",
        "BNB", ["https://bsc-dataseed.binance.org"], ["https://bscscan.com"], [])
}

async function addBSCTestnet() {
    return addChain("0x61", "BSC-Testnet",
        "BNB", ["https://data-seed-prebsc-1-s2.binance.org:8545/"], ["https://testnet.bscscan.com"], [])
}

function shortAddr(addr) {
    if (addr.startsWith('0x') && addr.length == 42) {
        return addr.substring(0, 6) + '...' + addr.substring(addr.length - 4);
    }
    return '';
}

function formatEther(bn, digits = 2) {
    if (bn) {
        try {
            let s = ethers.utils.formatEther(bn);
            return formatNumber(s, digits);
        } catch (e) {
            // console.error(e);
        }
    }
    return "0";
}

function formatNumber(s, digits = 2) {
    if (s) {
        let strNum = s
        // let strNum = Number(s).toLocaleString("en-US", {
        //     minimumFractionDigits: digits,
        //     maximumFractionDigits: digits
        // });

        // Truncate at digits
        let bigNum = new Big(s)
        bigNum.round(digits, Big.roundDown).toFixed()
        strNum = Number(s).toLocaleString("en-US", {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
        });
        return strNum;
    }
    return "0";
}

/**
 * 
 * @param {*} num 
 * @param {*} ratio 0 ~ 100 as percent
 * @param {*} digits number of precision digits 1, 2, 3, ... (0 means integer)
 * @returns 
 */
function ratioNumber(num, ratio, digits) {
    if (num && ratio > 0) {
        let bigNum = new Big(num).times(ratio).div(100)
        return formatNumber(bigNum.round(digits, Big.roundDown).toNumber(), digits)
    }
    return 0;
}


export default {
    NETWORKS,
    detect,
    isAvailable,
    connectWallet,
    getChainId,
    switchChain,
    addChain,
    requestWatchAsset,
    addToken,       // short version of requestWatchAsset
    addBSC,
    addBSCTestnet,
    shortAddr,
    formatEther,
    formatNumber,
    ratioNumber,
}